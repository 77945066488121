<template>
    <v-card>
        <v-card-title class="text-h3 mb-2">Confidentialité</v-card-title>
        <v-card-subtitle>En apprendre d'avantage sur la protection et la sécurisation de vos données.</v-card-subtitle>
        <v-card-text>
            <v-btn block color="secondary" class="mb-4">Lire la déclaration de Confidentialité</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name : 'Password',
}
</script>